<template>
  <div class="container">
    <header>
      <img
        src="@/assets/newImg/arrow_left.png"
        width="18px"
        height="15px"
        @click="$router.go(-1)"
      />
      <div class="title">{{ $t('user').MyShareCode.toUpperCase() }}</div>
    </header>
    <div class="share_popup">
      <div class="cord_box">
        <qrcode-vue
          :value="link"
          :size="size"
          level="H"
          class="qrcode"
        ></qrcode-vue>
      </div>
      <div class="cord_txt">
        {{ $t('user').MyShareCode.toUpperCase() }}：{{
          userInfo.InvitationCode
        }}
      </div>
      <div class="cord_btn" @click="onCopy(link)">
        {{ $t('user').CopyShareCode.toUpperCase() }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import userApi from '@/api/user'
import QrcodeVue from 'qrcode.vue'
export default {
  components: {
    QrcodeVue
  },
  data() {
    return {
      userInfo: {},
      link: '',
      size: 280
    }
  },
  computed: {
    ...mapGetters(['basicConfig'])
  },
  mounted() {
    this.getUserInfo()
  },
  methods: {
    async getUserInfo() {
      const aBase = await userApi.getAccountBase()
      this.userInfo = aBase.Data
      const res = await userApi.getClientConfig()
      this.link =
        res.Data.ShareUrl + '/#/register?code=' + this.userInfo.InvitationCode
    },
    //复制方法
    onCopy(val) {
      navigator.clipboard.writeText(val).then(() => {
        this.$toast(this.$t('common').CopySuccess)
      })
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  min-height: 100vh;
  width: 100%;
  background-image: url(../../assets/newImg/donate_bg.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 0 0 20px 0;
}
header {
  display: flex;
  justify-content: left;
  align-items: center;
  padding: 20px;
  .title {
    text-align: center;
    width: 350px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 18px;
    color: #ffffff;
  }
}
.share_popup {
  margin: 0 20px;
  padding-bottom: 10px;
  // height: 420px;
  background: #ffffff;
  box-shadow: 0px 3px 12px 0px rgba(73, 47, 182, 0.75);
  border-radius: 15px;
  .cord_box {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
  }
  .cord_txt {
    font-family: PingFang SC;
    font-weight: 800;
    font-size: 18px;
    color: #333333;
    text-align: center;
  }
  .cord_btn {
    margin: 20px 30px;
    height: 44px;
    background: linear-gradient(0deg, #ff9002 0%, #fcb81d 100%);
    box-shadow: 0px 2px 4px 0px rgba(173, 61, 0, 0.75);
    border-radius: 22px;
    font-family: PingFang SC;
    font-weight: 800;
    font-size: 13px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
